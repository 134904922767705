@keyframes skeleton {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.m-skeleton {
  display: flex;
  width: 100%;
  overflow: hidden;

  &__item {
    min-height: calc(1rem * #{$line-height-base});
    background-color: var(--color-skeleton-bg, #eee);
    cursor: wait;
    animation: skeleton 1.5s linear infinite alternate;

    &--input {
      height: $input-height-base;
    }
  }
}
