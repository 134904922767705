$m-restaurants-padding-block: 8px !default;
$m-restaurants-border-radius: $border-radius-base !default;
$m-restaurants-form-wrapper-max-width: var(--form-wrapper-max-width) !default;
$m-restaurants-content-margin-block-end: $base-spacing-large !default;
$m-restaurants-card-padding: map-get($spacers, 6) map-get($spacers, 5) !default;
$m-restaurants-card-background-color: transparent !default;

.m-restaurants {
  padding-block: $m-restaurants-padding-block;

  // Prevent visible outline after triggering foucus in `RestaurantSelection.tsx`
  &:focus-visible {
    outline-width: 0;
  }

  &__content {
    margin-block-end: $m-restaurants-content-margin-block-end;
  }

  &__heading {
    margin-block: 0 map-get($spacers, 5);
  }

  &__form-wrapper {
    max-width: $m-restaurants-form-wrapper-max-width;
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: $m-restaurants-card-padding;
    height: 100%;
    background-color: $m-restaurants-card-background-color;
    border-radius: $m-restaurants-border-radius;
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-separator);

    &--selected {
      border-color: var(--color-primary);
    }
  }

  &__card-title {
    margin-block: 0 map-get($spacers, 4);
    font-weight: $font-weight-bold;
  }

  &__card-icon {
    margin-inline-end: 8px;
    color: #a2a9b0;
  }

  &__card-action {
    margin-block-start: map-get($spacers, 4);
  }

  &__btn-collapse {
    border: 0;
    background: transparent;
  }
}
