$m-flex-layout-gutter: max(24px, $grid-gutter-width) !default;
$m-flex-layout-aside-background: transparent !default;
$m-flex-layout-content-width: 77% !default;
$m-flex-layout-aside-width: 23% !default;
$m-flex-layout-aside-max-width: 280px !default;

.m-flex-layout {
  @media screen and (min-width: $grid-float-breakpoint) {
    display: flex;
    flex-flow: row nowrap;
    gap: $m-flex-layout-gutter; // Add gutter between this element children. If there is one element then no gutter
  }

  &__content {
    &--middle {
      align-self: center;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
      flex: 1 1 $m-flex-layout-content-width;
    }
  }

  &__aside {
    display: none;
    background: $m-flex-layout-aside-background;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: block;
      flex: 0 0 $m-flex-layout-aside-width;
      max-width: $m-flex-layout-aside-max-width;
    }

    &--last {
      order: 10;
    }
  }

  &--column {
    flex-direction: column;
    gap: 0;

    .m-flex-layout__aside,
    .m-flex-layout__content {
      flex-basis: auto; // can't be 100% because of IE 11 bug (height for flex items is set to 0)
      max-width: none;
    }
  }

  &--auto {
    display: flex;
    flex-flow: row wrap;

    > * {
      flex: 1 1 auto;
    }
  }
}
