$m-fulfillment-time-spacing-block: map-get($spacers, 4) !default;
$m-fulfillment-slider-content-scrollbar-gutter: 6px !default;

.m-fulfillment-time {
  &__slider {
    display: flex;
    gap: map-get($spacers, 2);
    padding-block-start: $m-fulfillment-time-spacing-block;
    padding-block-end: calc(#{$m-fulfillment-time-spacing-block} - #{$m-fulfillment-slider-content-scrollbar-gutter});
    background-color: var(--modal-bg, #fff);
    z-index: 1;
  }

  &__list {
    &:only-child {
      margin-block-start: $m-fulfillment-time-spacing-block;
    }
  }

  &__slider-arrow {
    width: 30px;
    height: 30px;
    align-self: center;
    flex-shrink: 0;
    font-size: 14px;
    border: 1px solid var(--color-separator);
    border-radius: 50%;

    &--prev {
      margin-inline-end: map-get($spacers, 2);
    }

    &--next {
      margin-inline-start: map-get($spacers, 2);
    }
  }

  &__slider-content {
    @include custom-scrollbar(#bfbfbf);
    display: flex;
    flex: 1 1 100%;
    gap: map-get($spacers, 2);
    padding-block-end: $m-fulfillment-slider-content-scrollbar-gutter; // For scrollbar gutter purposes
    overflow-x: auto;
    scroll-behavior: smooth;
  }

  &__slider-item {
    flex: 0 0 12ch;

    .m-fulfillment-time__slider--pagination & {
      flex: 1 1 11ch;
    }
  }

  &__day {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block: map-get($spacers, 3);
    padding-inline: map-get($spacers, 4);
    border: 2px solid var(--color-separator);
    border-radius: $border-radius-base;
    text-align: start;
    font-family: inherit;
    transition: none;

    &.is-active {
      background-color: color-mix(in srgb, var(--color-primary) 10%, transparent);
      border-color: var(--color-primary, $brand-primary);
    }

    &.is-disabled {
      color: var(--text-muted, #{$text-muted});
      opacity: $inactive-opacity;
      border-color: var(--color-separator);
    }
  }
}
