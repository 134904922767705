.popover {
  --popover-zindex: #{$zindex-popover};
  --popover-max-width: #{$popover-max-width};
  --popover-bg: #{$popover-bg};
  --popover-border-width: 1px;
  --popover-border-color: #{$popover-border-color};
  --popover-border-radius: #{$border-radius-large};
  --popover-inner-border-radius: #{$border-radius-large};
  --popover-box-shadow: 0 5px 10px rgba(#000, 0.2);
  --popover-header-padding-x: 14px;
  --popover-header-padding-y: 8px;
  --popover-header-bg: var(--popover-bg);
  --popover-body-padding-x: 14px;
  --popover-body-padding-y: 9px;
  --popover-arrow-width: 22px;
  --popover-arrow-height: 11px;
  --popover-arrow-border: var(--popover-border-color);

  z-index: var(--popover-zindex);
  display: block;
  max-width: var(--popover-max-width);
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: var(--popover-bg);
  background-clip: padding-box;
  border: var(--popover-border-width) solid var(--popover-border-color);
  border-radius: var(--popover-border-radius);
  box-shadow: var(--popover-box-shadow);

  &.show {
    opacity: 1;
  }

  .popover-arrow {
    display: block;
    width: var(--popover-arrow-width);
    height: var(--popover-arrow-height);

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0;
    }
  }
}

.bs-popover-top {
  > .popover-arrow {
    bottom: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width)); // stylelint-disable-line function-disallowed-list

    &::before,
    &::after {
      border-width: var(--popover-arrow-height) calc(var(--popover-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
    }

    &::before {
      bottom: 0;
      border-top-color: var(--popover-arrow-border);
    }

    &::after {
      bottom: var(--popover-border-width);
      border-top-color: var(--popover-bg);
    }
  }
}

/* rtl:begin:ignore */
.bs-popover-end {
  > .popover-arrow {
    left: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width)); // stylelint-disable-line function-disallowed-list
    width: var(--popover-arrow-height);
    height: var(--popover-arrow-width);

    &::before,
    &::after {
      border-width: calc(var(--popover-arrow-width) * .5) var(--popover-arrow-height) calc(var(--popover-arrow-width) * .5) 0; // stylelint-disable-line function-disallowed-list
    }

    &::before {
      left: 0;
      border-right-color: var(--popover-arrow-border);
    }

    &::after {
      left: var(--popover-border-width);
      border-right-color: var(--popover-bg);
    }
  }
}

/* rtl:end:ignore */

.bs-popover-bottom {
  > .popover-arrow {
    top: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width)); // stylelint-disable-line function-disallowed-list

    &::before,
    &::after {
      border-width: 0 calc(var(--popover-arrow-width) * .5) var(--popover-arrow-height); // stylelint-disable-line function-disallowed-list
    }

    &::before {
      top: 0;
      border-bottom-color: var(--popover-arrow-border);
    }

    &::after {
      top: var(--popover-border-width);
      border-bottom-color: var(--popover-header-bg);
    }
  }

  // This will remove the popover-header's border just below the arrow
  .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: var(--popover-arrow-width);
    margin-left: calc(-.5 * var(--popover-arrow-width)); // stylelint-disable-line function-disallowed-list
    content: "";
    border-bottom: var(--popover-border-width) solid var(--popover-header-bg);
  }
}

/* rtl:begin:ignore */
.bs-popover-start {
  > .popover-arrow {
    right: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width)); // stylelint-disable-line function-disallowed-list
    width: var(--popover-arrow-height);
    height: var(--popover-arrow-width);

    &::before,
    &::after {
      border-width: calc(var(--popover-arrow-width) * .5) 0 calc(var(--popover-arrow-width) * .5) var(--popover-arrow-height); // stylelint-disable-line function-disallowed-list
    }

    &::before {
      right: 0;
      border-left-color: var(--popover-arrow-border);
    }

    &::after {
      right: var(--popover-border-width);
      border-left-color: var(--popover-bg);
    }
  }
}

/* rtl:end:ignore */

.bs-popover-auto {
  &[data-popper-placement^="top"] {
    @extend .bs-popover-top;
  }
  &[data-popper-placement^="right"] {
    @extend .bs-popover-end;
  }
  &[data-popper-placement^="bottom"] {
    @extend .bs-popover-bottom;
  }
  &[data-popper-placement^="left"] {
    @extend .bs-popover-start;
  }
}

// Offset the popover to account for the popover arrow
.popover-header {
  padding: var(--popover-header-padding-y) var(--popover-header-padding-x);
  margin: 0; // Reset the default from Reboot
  font-size: var(--popover-header-font-size);
  background-color: var(--popover-header-bg);
  border-bottom: var(--popover-border-width) solid var(--popover-border-color);
  border-top-left-radius: var(--popover-inner-border-radius);
  border-top-right-radius: var(--popover-inner-border-radius);

  &:empty {
    display: none;
  }
}

.popover-body {
  padding: var(--popover-body-padding-y) var(--popover-body-padding-x);
}
